import { get, post, postFile} from "./axios";

const register = async (data) => {
  return post("/register", data);
}

const get_user_info = async (data) => {
  return post("/get_user_info", data);
}

const uploadData = async (file, email) => {
  return postFile("/upload", file, email);
};

const getJobs = async (data) => {
  return get("/get_jobs", data);
}

const addJob = async (data) => {
  return post("/add_job", data);
}

const deleteJob = async (data) => {
  return post("/delete_job", data);
}

const downloadResult = async (jobId) => {
  return get(`/download/${jobId}`);
}

const getPastData = async (data) => {
  return get("/get_past_data", data);
}

const getAdminDashboard = async () => {
  return get("/dashboard");
}

const apis = {
  register,
  get_user_info,
  uploadData,
  getJobs,
  addJob,
  deleteJob,
  getPastData,
  getAdminDashboard
};

export default apis;