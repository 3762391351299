import React, { useState, useEffect } from "react";

const fieldMappings = {
  "GWAS Catalog": {
    filterFields: ["pubmedId", "studyId", "diseaseTrait", "ancestry", "traitType"],
    headers: [
      { label: "PMID", key: "pubmedId" },
      { label: "Study Accession", key: "studyId" },
      { label: "Disease/Trait", key: "diseaseTrait" },
      { label: "Ancestry", key: "ancestry" },
      { label: "Sample Size", key: "sampleSize" },
      { label: "Trait Type", key: "traitType" },
      { label: "Download Result", key: "downloadLink", isLink: true },
    ],
  },
  "MVP": {
    filterFields: ["Trait", "Trait_Type", "Meta_Group", "Category", "SubCategory", "Description"],
    headers: [
      { label: "Trait", key: "Trait" },
      { label: "Description", key: "Description" },
      { label: "Trait Type", key: "Trait_Type" },
      { label: "Category", key: "Category" },
      { label: "SubCategory", key: "SubCategory" },
      { label: "Download Result", key: "downloadLink", isLink: true },
    ],
  },
  "Biobank Japan": {
    filterFields: ["Category", "Phenotype", "Cohort", "No. samples", "No. cases", "No. controls"],
    headers: [
      { label: "Phenotype", key: "Phenotype" },
      { label: "Category", key: "Category" },
      { label: "Cohort", key: "Cohort" },
      { label: "No. samples", key: "No.samples" },
      { label: "No. cases", key: "No.cases" },
      { label: "No. controls", key: "No.controls" },
      { label: "Download Result", key: "downloadLink", isLink: true },
    ]
  },
  "GBMI": {
    filterFields: ["phenotype", "phenotype_short", "sex", "ancestry", "Note", "biobank"],
    headers: [
      { label: "Phenotype", key: "phenotype" },
      { label: "Phenotype Short", key: "phenotype_short" },
      { label: "Sex", key: "sex" },
      { label: "Ancestry", key: "ancestry" },
      { label: "Note", key: "Note" },
      { label: "Download Multi-ancestry Results", key: "downloadLink", isLink: true },
    ],
  },
};

export const ResultPage = () => {
  const [activeTab, setActiveTab] = useState("GWAS Catalog");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 10;
  const maxPageNumbersToShow = 5;

  const dataSources = {
    "GWAS Catalog": "/results_meta_data.json",
    "MVP": "/mvp_meta_data.json",
    "Biobank Japan": "/bbjp_meta_data.json",
    "GBMI": "/gbmi_meta_data.json",
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    fetch(dataSources[activeTab])
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTableData(data);
        setLoading(false);
        setCurrentPage(1); // Reset to first page on data load
      })
      .catch((error) => {
        console.error("Error loading table data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [activeTab]);

  // Function to filter data based on activeTab's filterFields
  const filterData = (data, fields, term) => {
    if (!term) return data;
    const lowerTerm = term.toLowerCase();
    return data.filter((row) =>
      fields.some((field) => {
        const value = row[field];
        return value && value.toString().toLowerCase().includes(lowerTerm);
      })
    );
  };

  const currentFieldMapping = fieldMappings[activeTab] || fieldMappings["GWAS Catalog"];

  const filteredData = filterData(tableData, currentFieldMapping.filterFields, searchTerm);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleClick = (pageNumber) => setCurrentPage(pageNumber);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <div style={{ padding: '20px', marginTop: "56px", alignItems: "center", display: "flex", flexDirection: "column" }}>
      <h1>Public Results</h1>

      {/* Tabs */}
      <div style={{ display: 'flex', marginBottom: '10px', borderBottom: '2px solid #ddd', justifyContent: 'center' }}>
        {Object.keys(dataSources).map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setActiveTab(tab);
              setSearchTerm("");
              setCurrentPage(1);
            }}
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              border: 'none',
              borderBottom: activeTab === tab ? '3px solid #1b65b4' : 'none',
              backgroundColor: 'transparent',
              fontWeight: activeTab === tab ? 'bold' : 'normal',
              fontSize: '16px',
              color: activeTab === tab ? '#1b65b4' : '#333',
              marginRight: '10px',
            }}
          >
            {tab}
          </button>
        ))}
      </div>

      {activeTab === "MVP" && (
        <p style={{ textAlign: 'center', fontSize: '14px', color: '#555' }}>
          Please check the link for the full MVP data description: <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs002453.v1.p1" target="_blank" rel="noopener noreferrer">Link</a>
        </p>
      )}

      {activeTab === "Biobank Japan" && (
        <p style={{ textAlign: 'center', fontSize: '14px', color: '#555' }}>
          Please check the link for the full Biobank Japan data description: <a href="https://pheweb.jp/downloads" target="_blank" rel="noopener noreferrer">Link</a>
        </p>
      )}

      {activeTab === "GBMI" && (
        <p style={{ textAlign: 'center', fontSize: '14px', color: '#555' }}>
          Please check the link for the full GBMI data description: <a href="https://www.globalbiobankmeta.org/resources" target="_blank" rel="noopener noreferrer">Link</a>
        </p>
      )}

      {/* Search Bar */}
      <input
        type="text"
        placeholder={`Search in ${activeTab}`}
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          width: '80%',
          padding: '12px 20px',
          margin: '8px 0',
          boxSizing: 'border-box',
          fontSize: '16px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      />

      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          margin: '20px 0',
          fontSize: '14px',
          textAlign: 'left',
          fontFamily: '"Arial", sans-serif',
          color: '#333',
          border: '1px solid #999',
        }}>
          <thead>
            <tr>
              {currentFieldMapping.headers.map((header) => (
                <th
                  key={header.key}
                  style={{
                    padding: '10px',
                    border: '1px solid #999',
                    backgroundColor: '#f1f1f1',
                    fontWeight: 'bold',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase',
                    textAlign: 'center'
                  }}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.length > 0 ? (
              currentRows.map((row, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                  {currentFieldMapping.headers.map((header) => (
                    <td
                      key={header.key}
                      style={{
                        padding: '10px',
                        border: '1px solid #999',
                        textAlign: 'center'
                      }}
                    >
                      {header.isLink ? (
                        <a
                          href={row[header.key]}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#0056b3', textDecoration: 'underline' }}
                        >
                          Download
                        </a>
                      ) : header.key === "studyId" && activeTab === "GBMI" ? (
                        <a
                          href={`https://www.ebi.ac.uk/gwas/studies/${row[header.key]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#0056b3', textDecoration: 'underline' }}
                        >
                          {row[header.key]}
                        </a>
                      ) : header.key === "studyId" && activeTab === "GWAS Catalog" ? (
                        <a
                          href={`https://www.ebi.ac.uk/gwas/studies/${row[header.key]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#0056b3', textDecoration: 'underline' }}
                        >
                          {row[header.key]}
                        </a>
                      ) : (
                        row[header.key]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={currentFieldMapping.headers.length} style={{ textAlign: 'center', padding: '20px' }}>
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      {!loading && !error && totalPages > 1 && (
        <>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              style={{
                margin: '0 5px',
                padding: '5px 10px',
                backgroundColor: currentPage === 1 ? '#ccc' : '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '3px',
                cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
              }}
            >
              Previous
            </button>

            {pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handleClick(pageNumber)}
                style={{
                  margin: '0 5px',
                  padding: '5px 10px',
                  backgroundColor: currentPage === pageNumber ? '#007bff' : '#f1f1f1',
                  color: currentPage === pageNumber ? 'white' : 'black',
                  border: 'none',
                  borderRadius: '3px',
                  cursor: 'pointer'
                }}
              >
                {pageNumber}
              </button>
            ))}

            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              style={{
                margin: '0 5px',
                padding: '5px 10px',
                backgroundColor: currentPage === totalPages ? '#ccc' : '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '3px',
                cursor: currentPage === totalPages ? 'not-allowed' : 'pointer'
              }}
            >
              Next
            </button>
          </div>

          {/* Current Page Info */}
          <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: '#555' }}>
            Page {currentPage} of {totalPages}
          </p>
        </>
      )}
    </div>
  );
};
