import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // Removed DialogContentText import
  DialogActions,
  Button,
  Typography,
  Divider,
  Box, // Added Box import
} from '@mui/material';
import { styled } from '@mui/system';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    padding: theme.spacing(3),
    boxShadow: '0 6px 10px rgba(0,0,0,0.15)',
    backgroundColor: '#f7f9fc',
    color: '#333',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2196f3',
  color: 'white',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2aad3d',
  color: 'white',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#007d00',
  },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.dark,
}));

const MultiReviewWindow = ({ open, title, data, onConfirm, onCancel }) => {
  const parameters_methods_dict = {
    "nlambda": "(PROSPER-pesudo) nlambda",
    "lambda_min_ratio": "(PROSPER-pesudo) lambda_min_ratio",
    "ndelta": "(PROSPER-pesudo) ndelta",
    "Ll": "(PROSPER-pesudo) Ll",
    "Lc": "(PROSPER-pesudo) Lc"
  };

  const toArray = (field) => {
    if (Array.isArray(field)) return field;
    if (typeof field === 'string') return field.split(',').map((item) => item.trim());
    return [];
  };

  const sources = toArray(data.source);
  const details = toArray(data.detail);
  const names = toArray(data.name);
  const types = toArray(data.trait_type);
  const populations = toArray(data.population);
  const column = toArray(data.column);
  const number_of_traits = sources.length;

  let fomatParaDict = {};

  if (data && data.paraDict && data.methods) {
    if (data.methods.includes("PROSPER")) {
      data.methods = data.methods.map(method => method === "PROSPER" ? "PROSPER-pseudo" : method);
    }
    Object.entries(data.paraDict).forEach(([key, value]) => {
      if (key in parameters_methods_dict) {
        fomatParaDict[parameters_methods_dict[key]] = value;
      }
    });
  }


  return (
    <CustomDialog
      open={open}
      onClose={onCancel}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-description"
    >
      <DialogTitle
        id="custom-dialog-title"
        sx={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#333' }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {/* Replace DialogContentText with Box */}
        <Box id="custom-dialog-description" sx={{ color: '#555' }}>
          <SectionHeader>Job Information</SectionHeader>
          <Typography>
            <strong>Job Name:</strong> {data.job_name}
          </Typography>
          <Typography>
            <strong>Job Type:</strong> {data.type}
          </Typography>
          <Typography>
            <strong>Job Methods:</strong> {data.methods && data.methods.join(', ')}
          </Typography>
          <Typography>
            <strong>Email Notification:</strong> {data.email}
          </Typography>
          <Divider sx={{ marginY: 2 }} />

          <SectionHeader>Parameters</SectionHeader>
          {data.paraDict && Object.entries(data.paraDict).length > 0 ? (
            Object.entries(fomatParaDict).map(([key, value]) => (
              <Typography key={key}>
                {key}: {value}
              </Typography>
            ))
          ) : (
            <Typography>
              <i>Default Parameters</i>
            </Typography>
          )}
          <Divider sx={{ marginY: 2 }} />

          <SectionHeader>Trait Information</SectionHeader>
          {number_of_traits > 0 ? (
            sources.map((source, index) => (
              <div key={index}>
                <Typography>
                  <strong> - Ancestry {index + 1} - </strong>
                </Typography>
                <Typography>
                  <strong>Source:</strong> {source}
                </Typography>
                <Typography>
                  <strong>Detail:</strong> {details[index] || 'N/A'}
                </Typography>
                {source === 'Query Data' && (
                  <Typography>
                    <strong>Trait ID:</strong> {column[index]?.id || 'N/A'}
                  </Typography>
                )}
                <Typography>
                  <strong>Name:</strong> {names[index] || 'N/A'}
                </Typography>
                <Typography>
                  <strong>Type:</strong> {types[index] || 'N/A'}
                </Typography>
                <Typography>
                  <strong>Population:</strong> {populations[index] || 'N/A'}
                </Typography>
                <br />
              </div>
            ))
          ) : (
            <Typography>No trait information available.</Typography>
          )}

          {/* <Divider sx={{ marginY: 2 }} />
          <SectionHeader>Trait Details</SectionHeader>
          {number_of_traits > 0 ? (
            column.map((trait, index) => (
              <div key={index}>
                <Typography>
                  <strong> - Ancestry {index + 1} - </strong>
                </Typography>
                {trait && Object.keys(trait).length > 0 && sources[index] !== 'Query Data' ? (
                  Object.entries(trait)
                    .filter(([key, value]) => value !== '')
                    .map(([key, value]) => (
                      <Typography key={key}>
                        <strong>{key}:</strong> {value}
                      </Typography>
                    ))
                ) : (
                  <Typography>Default Column Names</Typography>
                )}
                <br />
              </div>
            ))
          ) : (
            <Typography>No trait details available.</Typography>
          )} */}

        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onConfirm} autoFocus>
          Confirm
        </CustomButton>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default MultiReviewWindow;
